import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 8; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/21-sep-3/pic${i}.jpg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function WorldLiteracyDay2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">World Literacy Day</h1>

        <p>
          <strong>
            "Literacy is not just about educating, it is a unique and powerful
            tool to eradicate poverty and a strong means for social and human
            progress."
          </strong>
          <br />
          <br />
          The World Literacy Day was celebrated by the Pre- Primary department
          of Chinmaya Vidyalaya, New Delhi, on 6th September 2023. It aimed at
          helping little learners to raise awareness about the value and
          importance of literacy. They were shown an educational moral story
          *“Education Counts”*which is an effective way to convey the message
          about the importance of literacy in an engaging and relatable manner
          for young minds and the inclusion of a fun filled hopscotch game in
          the celebration added an element of enjoyment and interaction for the
          children. Combing learning with play can make the concept of literacy
          more appealing and memorable for them. The day was full of learning ,
          fun and frolic for all the learners.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
